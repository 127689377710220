

.photoBox {
  width: 100%;
  height: 0;
  padding-bottom: 33%;
  position: relative;
  overflow: hidden;
  background-color: #26272d;
  outline: none;

  &--modal {
    position: relative;

    img {
      width: auto;
      height: 80vh;
      object-fit: cover;
    }

    button {
      background: transparent;
      border: none;
      color: #fff;
      font-size: 3em;
      cursor: pointer;
      text-shadow: black 1px 0 10px;
      cursor: pointer;

      &:hover {
        color: rgb(219, 219, 219);
      }

      &.left {
        position: absolute;
        left: -80px;
      }

      &.right {
        position: absolute;
        right: -80px;
      }

      &.close {
        position: absolute;
        top: -30px;
        right: 0;
      }
    }

    transition: opacity 0.5s ease-in-out;
    opacity: 0;
  }

  &--modal.is-open {
    opacity: 1;
    position: relative;
    z-index: 5;
    width: 56vw;
    height: 70vh;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 16%;
    outline: none;
  }
}

.colorbox {
  cursor: pointer;
}
.ReactModal__Overlay {
//   background-color: rgba(0, 0, 0, 0.8) !important;
  z-index: 111;

  &--after-open {
    background-color: rgba(0, 0, 0, 0.8) !important;
  }
}

.photoBox--modal.is-open {
//   background-color: rgba(0, 0, 0, 0.8) !important;
}

@media screen and (max-width: 800px) {
  .gallery {
    padding: 0 5%;
  }
  .photoBox {
    width: 110%;
    height: 0;
    padding-bottom: 133%;
    position: relative;
    overflow: hidden;
    background-color: #26272d;
    left: -5%;

    &--images {
      cursor: pointer;
      background-color: black;
    }

    &--modal {
      position: relative;

      img {
        width: 100%;
        height: 70vh;
        object-fit: contain;
      }

      button {
        background: transparent;
        border: none;
        color: #fff;
        font-size: 3em;
        cursor: pointer;
        text-shadow: black 1px 0 10px;

        &:hover {
          color: rgb(219, 219, 219);
        }

        &.left {
          position: absolute;
          z-index: 6;
          left: 20px;
        }

        &.right {
          position: absolute;
          right: 20px;
        }

        &.close {
          position: absolute;
          top: 0;
          right: 20px;
        }
      }

      transition: opacity 0.5s ease-in-out;
      opacity: 0;
    }

    &--modal.is-open {
      opacity: 1;
      position: relative;

      z-index: 5;
      width: 100%;
      height: 70vh;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 16%;
    }
  }
}

@media (width<414px) {
  .photoBox {
    &--modal {
      button {
        background: transparent;
        border: none;
        color: #fff;
        font-size: 3em;
        cursor: pointer;
        text-shadow: black 1px 0 10px;

        &:hover {
          color: rgb(219, 219, 219);
        }

        &.left {
          position: absolute;
          z-index: 6;
          left: 0;
        }

        &.right {
          position: absolute;
          right: 0;
        }

        &.close {
          position: absolute;
          top: 10px;
          right: 0;
        }
      }
    }
  }
}
