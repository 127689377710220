.textariaForm {
    height: 140px !important;
    margin-top: 14px !important;
    margin-bottom: 20px !important;
}

.button_formNew {
    width: 180px !important;
}

.price-form-card__topNew {
    margin-bottom: 20px !important;
}