.center-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 300px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0;
  transition: transform 0.3s ease-in-out;
  outline: none;
}

.modal-overlay-center {
  background-color: rgba(0, 0, 0, 0.1);
}

.modalCenter {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.closeButtonAlertModal {
  position: absolute;
  margin-right: 0;
  bottom: 20px;
}

.wrapperAlert {
  width: 100%;
  height: 100%;
  padding: 24px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-family: "Avenir", "Arial", sans-serif;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
}

.closeModal {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 24px;
  height: 24px;
  opacity: 0.2;
  cursor: pointer;
  transition: opacity ease 0.5s;

  &:hover {
    opacity: 1;
  }
}

.closeModal::before,
.closeModal::after {
  content: "";
  position: absolute;
  top: 10px;
  display: block;
  width: 24px;
  height: 3px;
  background: #000;
}

.closeModal::before {
  transform: rotate(45deg);
}

.closeModal::after {
  transform: rotate(-45deg);
}

@media (width<630px) {
  .wrapperAlert {
    width: 100vw;
    height: 50vh;
  }
}
