
.Overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  width: 100%;
  inset: 0;
  height: 100%;
}

.Modal {
  position: fixed;
  // width: 80%;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  z-index: 999;
  // height: 100%;
}

.right-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 400px;
  height: 100%;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0;
  transition: transform 0.3s ease-in-out;
  outline: none;
}

.VideoModal {
width: 100%;
}
.Video-Overlay {
  width: 100%;
}
@media (width < 415px) {
  .right-modal {
    width: 100%;
  }
}

.modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.modalWindow {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
}

//old styles
.interactive-picture-form-wrapper {
  position: absolute;
  top: 200px;
  left: 0;
  bottom: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}

.interactive-picture-form-wrapper .interactive-picture-form__close-area {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 60;
}

@media (width>992px) {
  .main-screen {
    display: none;
  }
}

.interactive-picture-form-wrapper .interactive-picture-form {
  width: 400px;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #ffffff;
  position: fixed;
  z-index: 60;
  -webkit-transition: 0.22s ease-in-out;
  -o-transition: 0.22s ease-in-out;
  transition: 0.22s ease-in-out;
  overflow-y: auto;
}

.interactive-picture-form__close .interactive-picture-form__close-area {
  display: none;
}

.interactive-picture-form__close .interactive-picture-form {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}

.interactive-picture-form-wrapper .interactive-picture-form {
  overflow: visible;
}

.interactive-picture-form-wrapper {
  overflow: visible;
}

.interactive-picture-form-wrapper {
  position: relative !important;
  top: auto !important;
  left: auto !important;
  right: auto !important;
  bottom: auto !important;
}

.interactive-picture-form-wrapper .interactive-picture-form {
  margin-top: 60px;
  position: relative !important;
  top: auto !important;
  left: auto !important;
  right: auto !important;
  bottom: auto !important;
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
  width: 100%;
  max-width: 615px;
  margin-right: auto;
  margin-left: auto;

}

.interactive-picture-form-wrapper .interactive-picture-form .price-form-card {
  display: block;
  margin-bottom: 15px;
  background-color: #ffffff;
}

.interactive-picture-form-wrapper .interactive-picture-form {
  background-color: transparent;
}

.interactive-picture-form__close {
  pointer-events: auto;
}

.interactive-picture-form-wrapper .interactive-picture-form {
  overflow: visible;
}

.interactive-picture-form-wrapper {
  overflow: visible;
}
.interactive-picture-form {
  padding-left: 7.25px;
  padding-right: 7.25px;
  padding-bottom: 50px;
  padding: 0 20px;

}

// .interactive-picture-form-wrapper {
//   position: relative !important;
//   top: auto !important;
//   left: auto !important;
//   right: auto !important;
//   bottom: auto !important;
// }

.interactive-picture-form-wrapper .interactive-picture-form {
  margin-top: 20px;
  position: relative !important;
  top: auto !important;
  left: auto !important;
  right: auto !important;
  bottom: auto !important;
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
  width: 100%;
  max-width: 615px;
  margin-right: auto;
  margin-left: auto;
}

.interactive-picture-form-wrapper .interactive-picture-form .price-form-card {
  display: block;
  padding-bottom: 15px;
  border-bottom: 1px solid #8890a6;
  margin-bottom: 15px;
  /*background-color: #ffffff;*/
}

.interactive-picture-form-wrapper .interactive-picture-form {
  background-color: transparent;
}

.interactive-picture-form__close {
  pointer-events: auto;
}

.interactive-picture-form-wrapper .interactive-picture-form {
  z-index: 150;
}

.price-form-card .price-form-card__top {
  border-bottom: 1px solid #8890a6;
}

.price-form-card .price-form-card__top .price-form-card__img {
  width: 100%;
}

.price-form-card .price-form-card__top .price-form-card__img img {
  width: 100%;
}

.price-form-card .price-form-card__top .price-form-card__top-text {
  padding-top: 20px;
  padding-bottom: 20px;
}

.price-form-card .price-form-card__top .price-form-card__top-text strong {
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  display: block;
  text-align: center;
  margin-bottom: 7px;
}

.price-form-card .price-form-card__top .price-form-card__top-text span {
  font-weight: 500;
  color: #8890a6;
  text-align: center;
  display: block;
}

.price-form-card .price-form-card__bottom {
  padding-top: 20px;
}

.price-form-card .price-form-card__bottom .price-form-card__bottom-row {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 6px 6px;
  color: black;
}

.price-form-card
  .price-form-card__bottom
  .price-form-card__bottom-row
  .price-form-card__bottom-item {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.price-form-card
  .price-form-card__bottom
  .price-form-card__bottom-row
  .price-form-card__bottom-item:first-child {
  width: 45%;
}

.price-form-card
  .price-form-card__bottom
  .price-form-card__bottom-row
  .price-form-card__bottom-item:nth-child(2) {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
}

.price-form-card
  .price-form-card__bottom
  .price-form-card__bottom-row
  .price-form-card__bottom-item:last-child {
  width: 30px;
  margin-left: auto;
}

.price-form-card
  .price-form-card__bottom
  .price-form-card__bottom-row
  .price-form-card__bottom-item
  label {
  margin-left: auto;
}

.price-form-card
  .price-form-card__bottom
  .price-form-card__bottom-row
  .price-form-card__bottom-item
  label
  input {
  display: none;
}

.price-form-card
  .price-form-card__bottom
  .price-form-card__bottom-row
  .price-form-card__bottom-item
  label
  .price-form-card__bottom-item-checkbox {
  display: block;
  margin-left: auto;
  border: 1px solid lightgray;
  height: 24px;
  width: 24px;
}

.price-form-card
  .price-form-card__bottom
  .price-form-card__bottom-row
  .price-form-card__bottom-item
  label
  .price-form-card__bottom-item-checkbox:hover {
  cursor: pointer;
}

.price-form-card
  .price-form-card__bottom
  .price-form-card__bottom-row
  .price-form-card__bottom-item
  label
  .price-form-card__bottom-item-checkbox::after {
  opacity: 0;
  display: block;
  content: "";
  height: 23px;
  width: 23px;
  background-image: url("../../../../public/img/skin/checkmark.png");
  background-repeat: no-repeat;
  background-position: center;
  -webkit-transition: 0.22s ease-in-out;
  -o-transition: 0.22s ease-in-out;
  transition: 0.22s ease-in-out;
}

.price-form-card
  .price-form-card__bottom
  .price-form-card__bottom-row
  .price-form-card__bottom-item
  label
  input:checked
  + .price-form-card__bottom-item-checkbox::after {
  opacity: 1;
}

.price-form-card {
  display: none;
}
