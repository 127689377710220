.closeModalButton {
  border: none;
  outline: none;
  background-color: transparent;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 151;
  width: 24px;
  height: 24px;
  object-fit: cover;
  color: #ffeb00;

  &:hover {
    filter: opacity(0.5);
  }
}
