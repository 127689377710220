.checkbox-label {
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
  position: relative;
  min-width: 170px;

  &::before {
    display: block;
    content: "";
    background-color: #232123;
    width: 80px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid white;
    padding: 7px;
  }

  &::after {
    display: block;
    content: "";
    width: 24px;
    height: 24px;
    background-color: #c6cad6;
    transition: 0.22s ease-in-out;
    border-radius: 50%;
    position: absolute;
    left: 6px;
  }
}

.checked {
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
  position: relative;
  min-width: 170px;
  text-align: left;

  &::before {
    display: block;
    content: "";
    background-color: #232123;
    width: 80px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid white;
    padding: 7px;
  }

  &::after {
    display: block;
    content: "";
    width: 24px;
    height: 24px;
    background-color: #c6cad6;
    transition: 0.22s ease-in-out;
    border-radius: 50%;
    position: absolute;
    left: 40px;
  }
}

.checked::after {
  left: 50px;

  background-color: #ffe400;
  transition: 0.4s ease-in-out;
}

.checkmark {
  position: absolute;
  width: 24px;
  height: 24px;
  cursor: pointer;
  right: 20px;
  opacity: 0;

}

.checkbox-label-mark {
  position: absolute;
  width: 24px;
  height: 24px;
  color: #ffffff;
  cursor: pointer;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.2);
  right: 0;
}

.checked-mark {
  width: 24px;
  height: 24px;
  color: #ffffff;
  cursor: pointer;
  position: relative;
  border: 1px solid black;

  &::after {
    display: block;
    content: "";
    background-image: url(../../../../../public/img/skin/checkmark.png);
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    transition: 0.22s ease-in-out;
    position: absolute;
    background-position: center;
  }
}
